<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报(美术类)</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info">
      <el-row>
        <el-col :span="9">
          <span>科类:</span>
          <span class="info_2">{{ userInfo.is_wenli == 1 ? '文科' : '理科' }}</span>
          <span>专业课分:</span>
          <span class="info_2">{{ userInfo.z_score }}</span>
          <span>位次:&nbsp;</span>
          <span class="info_2">{{ oldScoreArt[0] }}</span>

          <span class="info_3" v-if="userInfo.z_score - art_score_line[1] > 0 && art_score_line[0] != 0"> <img
              src="@/assets/img/art_volunteer/1.png" alt="" style="width: 60px; vertical-align: bottom;"></span>
          <span class="info_4" v-else><img src="@/assets/img/art_volunteer/2.png" alt=""
              style="width: 60px; vertical-align: bottom;"></span>
        </el-col>

        <el-col :span="9">
          <span>文化课分:</span>
          <span class="info_2">{{ userInfo.score }}</span>
          <span>位次:&nbsp;</span>
          <span class="info_2">{{ userInfo.ranking }}</span>

          <span class="info_3" v-if="userInfo.score - art_score_line[0] > 0 && art_score_line[0] != 0"> <img
              src="@/assets/img/art_volunteer/1.png" alt="" style="width: 60px; vertical-align: bottom;"> </span>
          <span class="info_4" v-else><img src="@/assets/img/art_volunteer/2.png" alt=""
              style="width: 60px; vertical-align: bottom;"></span>
        </el-col>

        <el-col :span="4" :offset="2">
          <span>填报批次：</span>

          <span class="select">
            <el-select v-model="art_batch" placeholder="请选择" class="searchBatch" @change="getBatch" size="mini">
              <div v-for="(item) in batchList" :key="item.id">
                <el-option :label="item.name" :value="item.id">
                </el-option>
              </div>
            </el-select>
          </span>
        </el-col>
      </el-row>
      <div style="font-size: 12px;color: #666666;">由于河南省公布专业课分数位次为“五分一段”，可能显示位次与你实际位次不符，你可在个人中心编辑修改。</div>
    </div>
    <div class="nav">
      <div :class="{ act: this.nav_num == 0 }" @click="chooseNav(0)">
        <p>招生院校</p>
        <p>在豫有美术类招生计划的院校</p>

      </div>
      <div :class="{ act: this.nav_num == 1 }" @click="chooseNav(1)">
        <p>智能算分</p>
        <p>快速计算美术类考生所需的数据</p>

      </div>
      <div :class="{ act: this.nav_num == 2 }" @click="chooseNav(2)">
        <p>智能推荐</p>
        <p>科学推荐志愿填报方案</p>

      </div>
      <div :class="{ act: this.nav_num == 3 }" @click="chooseNav(3)">
        <p>我的志愿表</p>
        <p>当前已填{{ artFormList.length }}志愿位置</p>
      </div>
    </div>

    <router-view @Nav="setNav" @setTable="setTable" :art_batch="art_batch" v-if="art_batch" :oldScoreArt="oldScoreArt"
      :oldScore="oldScore" :oldLine="oldLine"></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Index",
  computed: { ...mapState(["userInfo", 'artFormList']) },
  data() {
    return {
      nav_num: 0,
      batchList: [
        { id: "1", name: "本科批次", code: "1", status: "1" },
        { id: "2", name: "高职高专", code: "2", status: "2" },
      ],
      art_batch: "1",
      is_wenli: 0,
      college_num: 0,
      new_line: {},
      old_line: {},
      art_score_line: [0, 0],
      oldScoreArt: ['', '', '', ''],
      oldScore: ['', '', '', ''],
      oldLine: [],
      globalData: {
        // 要传递给子组件的数据
        someData: 'Hello world'
      }
    };
  },
  watch: {
    art_batch() {
      this.setLineParam()
    },
    artFormList(e) {
      console.log(e)
    }
  },
  provide() {
    return {
      globalData: this.art_score_line,
      callBack: this.callBack
    }
  },


  created() {
    //设置批次 - 文理科
    this.is_wenli = this.userInfo.is_wenli
    this.getLineList()
    this.getEqualScore()
    this.getReport()

  },

  methods: {
    callBack() {
      this.$forceUpdate()
    },

    ...mapMutations(['setArtFromList']),
    //动态选择模块
    chooseNav(e) {
      this.nav_num = e;
      switch (e) {
        case 0:
          this.$router.push("/volunteer_art");
          return;
        case 1:
          this.$router.push("/volunteer_art/art_calculate");
          return;
        case 2:
          this.$router.push("/volunteer_art/art_volunteer");
          return;
        case 3:
          this.$router.push("/volunteer_art/report_table_art");
          return;
      }
    },
    setTable() {
      console.log(this.artFormList)
    },

    setNav(e) {
      this.nav_num = e;
    },

    getBatch() {
      this.getReport()
    },

    // 获取批次线
    getLineList() {
      this.$fecth
        .post("art_specialty_score/getArtLineScore", {
          year: 2024
        })
        .then((res) => {
          this.new_line = res.splice(0, 2)
          this.oldLine = res

          this.setLineParam()
        });

    },

    // 获取当年分数线。个人信息分数判断是否录取 
    setLineParam() {
      switch (this.art_batch) {
        case "1":
          this.art_score_line[0] = this.new_line[0].curture_score
          this.art_score_line[1] = this.new_line[0].major_score
          this.$forceUpdate()
          return;
        case "2":
          this.art_score_line[0] = this.new_line[1].curture_score
          this.art_score_line[1] = this.new_line[1].major_score
          this.$forceUpdate()
          return;
        default:
          return;
      }
    },

    getEqualScore() {
      this.$fecth
        .post("art_specialty_score/getArtEqualScore", {
          is_wenli: this.userInfo.is_wenli,
          z_score: this.userInfo.z_score,
          score: this.userInfo.score,
        })
        .then((res) => {
          if (res) {
            this.oldScoreArt = res.art
            this.oldScore = res.ordinary
          }
        });
    },

    // 获取志愿表
    getReport() {
      let data = {
        art_batch: this.art_batch,
        is_wenli: this.userInfo.is_wenli
      }
      this.$fecth.post('art_t_user_report/getUserReport', data).then((res) => {
        if (res.content == null) res.content = "[]"
        this.setArtFromList(JSON.parse(res.content))
      })
    }
  },
};
</script>

<style lang="less">
.searchBatch {
  .el-input__inner {
    border: 1px solid #2b7bf3;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    width: 110px;
  }

  .el-select:hover {
    border-color: #2b7bf3 !important;
  }

  .el-input__icon {
    line-height: 30px !important;
  }
}
</style>
<style lang="less">
.el-input__inner {
  font-size: 16px;
}
</style>

<style lang="less" scoped>
.nav {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  div {
    width: 219px;
    background: #459df6;
    padding: 8px 47px;

    p:first-child {
      color: white;
      font-size: 18px;
    }

    p:last-child {
      font-size: 14px;
      color: white;
    }
  }
}

.act {
  background: #2871fb !important;
  color: white;
}

.info {
  font-size: 16px;
  position: relative;

  span {
    display: inline-block;
  }

  margin: 0px 0 20px 0;

  .info_2 {
    color: #1787e0;
    margin-right: 25px;
  }
}
</style>